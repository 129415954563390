import { useRef } from 'react'

import CustomText, { FontWeight, TextSize } from '@/components/base/CustomText'
import useNewPosts from '@/hooks/useNewPosts'
import useSunetAuthors from '@/hooks/useSunetAuthors'
import { findAllPostByFilters } from '@/services/postsService'
import { Post as PostType } from '@/ts/types/post.types'

import Post from '../Post/Post'
import RecommendedProfileSnippets from '../RecommendedProfileSnippet/RecommendedProfileSnippets'
import NewPostsList from './NewPostsList'
import PostForm from './PostForm'
import PostList from './PostList'

export type CustomLinkTypes = 'internal' | 'external' | undefined

const Feed = (): JSX.Element => {
  const { handleReset, posts, handleSave, newPosts, handleCreate, objective, setObjective } = useNewPosts()

  const { author } = useSunetAuthors()

  const formRef = useRef()

  return (
    <div className="relative">
      <div className="mt-1">
        <PostForm
          addons={['image', 'youtube', 'article', 'twitter', 'file', 'video']}
          allowImages
          allowFiles
          allowVideos
          allowDetection
          onCreate={handleCreate}
          objective={objective}
          setObjective={setObjective}
          canSelectODS
          formRef={formRef}
        />
      </div>
      <div>
        <NewPostsList posts={posts} onSave={handleSave} namespace="feed" customKey="default" />
      </div>
      <div>{/* TODO: LiveWebinars */}</div>
      <div className="md:mt-10 mt-2">
        <CustomText size={TextSize.SubTitle2} weight={FontWeight.SemiBold} className="mb-4">
          Publicaciones de la comunidad
        </CustomText>
        <PostList
          key="default"
          customKey="default"
          namespace="feed"
          request={(requestData: unknown[]) =>
            findAllPostByFilters(requestData, {
              area: null,
              ods: null,
              date: null,
            })
          }
          renderPost={(item: PostType, index: number) => (
            <div key={item.UUID}>
              <Post addon={item} index={index} />
              {index === 5 && author.Type === 'user' ? (
                <div className="pb-4">
                  <RecommendedProfileSnippets />
                </div>
              ) : null}
            </div>
          )}
          topPosts={newPosts}
          onLoadNew={handleReset}
          showTopPost={true}
        />
      </div>
    </div>
  )
}

export default Feed
